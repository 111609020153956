html[data-theme=light] {
  --text-primary: rgba(0, 0, 0, 0.87);
  --text-secondary: rgba(0, 0, 0, 0.6);
  --text-third: rgba(0, 0, 0, 0.37);

  --icon-primary: rgba(0, 0, 0, 0.87);
  --icon-secondary: rgba(0, 0, 0, 0.6);
  --icon-third: rgba(0, 0, 0, 0.37);

  --astro-table-border: #BDBDBD;
  --astro-table-border-light: #E0E0E0;

  --element: rgba(88, 88, 88, 0.2);
  --element-neutral: rgba(65, 65, 65, 0.08);

  --workspace-background: white;
  --workspace-widget-background: white;

  --input-background: rgba(0, 0, 0, 0.15);
  --input-border: rgba(255, 255, 255, 0.15);
  --input-background-active: #AEAEAE;

  --circle-internal-background: #F6F9FB;
  --circle-border: #B0BEC5;
  --circle-zodiacs-border: none;
  --circle-zodiacs-background: none;
  --circle-zodiacs-icons-land: var(--circle-zodiacs-elements-common);
  --circle-zodiacs-icons-air: var(--circle-zodiacs-elements-common);
  --circle-zodiacs-icons-water: var(--circle-zodiacs-elements-common);
  --circle-zodiacs-icons-fire: var(--circle-zodiacs-elements-common);
  --circle-zodiacs-sectors-display-light: block;

  --circle-houses-usual: #424242;

  --circle-aspects-90: #191919;
  --circle-aspects-180: #191919;
}