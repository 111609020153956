/* Dark theme by default */

html {
  --accent-blue: #4093F4;
  --accent-red: #EF5350;
  --accent-magenta: #BA68C8;

  --astro-table-border: #4c4c4c;
  --astro-table-border-light: #333333;

  --astro-table-object-major: #73B330;
  --accent-green: #73B330;

  --aspects-table-border: #4B4B4B;
  --aspects-table-border-light: #626262;

  --background: #1d1d1d;

  --bg-100: #151515;
  --bg-200: #1d1d1d;
  --bg-300: rgba(34, 34, 34, 1);
  --bg-400: #2a2a2a;
  --bg-410: #2a2a2a;
  --bg-500: #333333;
  --bg-600: #3b3b3b;
  --bg-700: #4c4c4c;
  --bg-sidebar: #2a2a2a;
  --bg-clear-modal: #1b1b1b;
  --bg-share: #151515;
  --bg-share-hover: rgba(255, 255, 255, 0.08);

  --bg-session: #2d2d2d;
  --bg-interval: #222222;
  --bg-break: repeating-linear-gradient(-45deg, #222222 0px, #222222 4px, #151515 4px, #151515 8px);

  --button-bg: rgba(255, 255, 255, 0.05);
  --button-bg-hover: rgba(255, 255, 255, 0.15);
  --button-border: rgba(255, 255, 255, 0.08);

  --circle-internal-background: #1d1d1d;
  --circle-border: #555555;

  --colors-purple: purple;
  --colors-green: green;
  --colors-orange: #F59300;
  --colors-white: white;
  --colors-red: #EF5350;

  --warning-color: #ED682E;

  --text-primary: rgba(255, 255, 255, 0.87);
  --text-secondary: rgba(255, 255, 255, 0.6);
  --text-third: rgba(255, 255, 255, 0.37);

  --icon-primary: rgba(255, 255, 255, 0.87);
  --icon-secondary: rgba(255, 255, 255, 0.6);
  --icon-third: rgba(255, 255, 255, 0.37);

  --element: rgba(255, 255, 255, 0.2);
  --element-light: rgba(255, 255, 255, 0.1);
  --element-neutral: rgba(255, 255, 255, 0.08);
  --element-neutral-hover: rgba(255, 255, 255, 0.12);
  --element-neutral-focus: rgba(255, 255, 255, 0.14);
  --element-disabled: rgba(255, 255, 255, 0.05);
  --element-neo: rgba(255, 255, 255, 0.05);
  --element-selected: linear-gradient(0deg, rgba(76, 154, 245, 0.12), rgba(76, 154, 245, 0.12)), rgba(255, 255, 255, 0.08);

  --bg-element-hover: rgba(255, 255, 255, 0.05);
  --bg-element-hover-hover: rgba(255, 255, 255, 0.08);

  --circle-zodiacs-elements-common: rgba(33, 33, 33, 0.6);

  --workspace-background: #191919;
  --workspace-widget-background: #3B3B3B;

  --workspace-box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.4), 0px 0.6px 1.8px rgba(0, 0, 0, 0.2);

  --layer-color: rgba(0, 0, 0, 0.2);
  --layer-text-shadow-color: rgba(0, 0, 0, 0.25);

  --input-background: rgba(0, 0, 0, 0.15);
  --input-border: rgba(255, 255, 255, 0.15);
  --input-background-active: #4C4C4C;

  --button-bg: rgba(255, 255, 255, 0.05);
  --button-bg-hover: rgba(255, 255, 255, 0.15);
  --button-border: rgba(255, 255, 255, 0.08);

  --circle-internal-background: #1D1D1D;

  

  /*
  --circle-border: #555555;

  --circle-zodiacs-border: var(--circle-border);
  --circle-zodiacs-background: #262626;
  --circle-zodiacs-elements-land: #66BB6A;
  --circle-zodiacs-elements-air: #E2EEF9;
  --circle-zodiacs-elements-water: #537DCF;
  --circle-zodiacs-elements-fire: #FF8A65;
  --circle-zodiacs-icons-land: var(--circle-zodiacs-elements-land);
  --circle-zodiacs-icons-air: var(--circle-zodiacs-elements-air);
  --circle-zodiacs-icons-water: var(--circle-zodiacs-elements-water);
  --circle-zodiacs-icons-fire: var(--circle-zodiacs-elements-fire);
  --circle-zodiacs-sectors-display-light: none;

  --circle-houses-usual: #ffffff;
  --circle-houses-special: #EF5350;
  --circle-houses-alternative: #7986CB;

  --circle-aspects-0: #328BDC;
  --circle-aspects-30: rgba(255, 0, 0, 0.25);
  --circle-aspects-36: green;
  --circle-aspects-40: pink;
  --circle-aspects-45: lightgray;
  --circle-aspects-60: #EF5350;
  --circle-aspects-72: lightgreen;
  --circle-aspects-80: pink;
  --circle-aspects-90: #ffffff;
  --circle-aspects-100: pink;
  --circle-aspects-108: green;
  --circle-aspects-120: #EF5350;
  --circle-aspects-135: lightgray;
  --circle-aspects-144: green;
  --circle-aspects-150: lightgray;
  --circle-aspects-180: #ffffff; */

  --workarea-width: 48rem;

  --overlayZindex: 100;

  --easeOutback: cubic-bezier(0.34, 1.56, 0.64, 1);
  --easeOutCirc: cubic-bezier(0, 0.55, 0.45, 1);
  --easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
}