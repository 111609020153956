@font-face {
  font-family: 'Apercu Pro';
  src: url('src/static/fonts/ApercuPro-Regular.woff2') format('woff2'),
      url('src/static/fonts/ApercuPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu Pro';
  src: url('src/static/fonts/ApercuPro-Medium.woff2') format('woff2'),
      url('src/static/fonts/ApercuPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

:root {
  --vertical-space: 3rem;
  --sidebar-space: 3rem;
  --header-height: 4rem;
  --footer-height: 1.25rem;
  --animation-type: cubic-bezier(0.7,0,0.3,1);
  --animation-time: 0.3s;


  /* dark */
  --workspace-background: #191919;
  --bg-100: #151515;

  --element-neutral: rgba(255, 255, 255, 0.08);

  --input-background: rgba(0, 0, 0, 0.15);
  --input-background-hover: rgba(0, 0, 0, 0.2);
  --input-background-focus: rgba(0, 0, 0, 0.3);
  --input-background-active: #4c4c4c;

  --bg-element-neutral: rgba(255, 255, 255, 0.08);
  --bg-element-hover: rgba(255, 255, 255, 0.05);
  --bg-element-hover-hover: rgba(255, 255, 255, 0.08);

  --input-border: rgba(255, 255, 255, 0.15);
  --input-border-hover: rgba(255, 255, 255, 0.24);
  --input-border-focus: rgba(255, 255, 255, 0.34);

  --text-primary: rgba(255, 255, 255, 0.87);
  --text-secondary: rgba(255, 255, 255, 0.6);
  --text-third: rgba(255, 255, 255, 0.37);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  box-sizing: border-box;
}

html {
  --font-size: 1rem;
  font-size: var(--font-size);
}

@media (max-width:1440px){
  html{
    font-size:14px;
  }
}
  
@media (-webkit-max-device-pixel-ratio:1.25) and (max-width:1600px),(max-width:1366px){
  html{
    font-size:12px;
  }
}

@media (max-width:1280px){
  html{
    font-size:10px;
  }
}

@media (-webkit-max-device-pixel-ratio:1.5) and (max-width:1366px),(max-width:1024px){
    html{
      font-size:10px;
    }
}

@media (max-width:768px){
  html{
    font-size:10px;
  }
}
  
@media (max-width:480px){
  html{
    font-size:16px
  }
}


body {
  width: 100vw;
  min-height: 100vh;
  font-family: 'Apercu Pro', sans-serif;
  overflow-x: hidden;
  overflow-y: auto;

  background-color: var(--workspace-background);
  color: var(--text-primary);
}

a {
  color: inherit;
  text-decoration: none;
}